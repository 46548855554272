import { createUserManager } from 'redux-oidc';
import { config as AppConfig } from '../config';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';

const authority = process.env.NODE_ENV === 'development' ? AppConfig.dev.baseUrl + AppConfig.dev.appName : window.location.origin;
const clientId = process.env.REACT_APP_CLIENT_ONLY === 'true' ? 'rtx200spaclient_devclientonly' : 'rtx200spaclient';

const userManagerConfig: UserManagerSettings = {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  client_id: clientId,
  redirect_uri: window.location.origin + '/callback',
  response_type: 'id_token token',
  scope: 'openid profile email rtx200api roles',
  authority: authority,
  silent_redirect_uri: window.location.origin + '/silent-renew',
  automaticSilentRenew: true,
  post_logout_redirect_uri: window.location.origin + '/login', // IMPORTANT: the end / is required.
  accessTokenExpiringNotificationTime: 10,

  //PTr added for testing

  silentRequestTimeout: 100000,
  checkSessionInterval: 100000,

  // silentRequestTimeout: false,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
