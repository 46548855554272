import { IconType, VerticalMenu, VerticalMenuItem } from '../../../lib/carbon-ui';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
//Tab Development
import useMenuSelectInterceptor from '../../../lib/hooks/useMenuSelectInterceptorTabs';
//import useMenuSelectInterceptor from '../../../lib/hooks/useMenuSelectInterceptor';
import IWBSMenuItem from '../../../lib/interfaces/Menu/IWBSMenuItem';

// Define a type for the props
interface DefaultMenuBarVerticalProps {
  menuExpanded: boolean;
  toggleMenu: () => void;
}

const DefaultMenuBarVertical: React.FC<DefaultMenuBarVerticalProps> = ({ menuExpanded, toggleMenu }) => {
  const menu = useSelector((state: ApplicationState) => state?.menu?.menu);
  const currentlySelectedTabId = useSelector((state: ApplicationState) => state.app.currentTabId);

  const [menuWidth, setMenuWidth] = useState("322px");
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [expandedItems, setExpandedItems] = useState<{ [key: number]: boolean }>({});
  const navigate = useNavigate();
  const { interceptMenuClick } = useMenuSelectInterceptor();

  // Update screen dimensions on window resize
  useEffect(() => {
    const updateScreenDimensions = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', updateScreenDimensions);
    return () => {
      window.removeEventListener('resize', updateScreenDimensions);
    };
  }, []);

  // Navigate to the currently selected tab when it changes
  useEffect(() => {
    if (currentlySelectedTabId !== '') {
      navigate(currentlySelectedTabId);
    }
  }, [currentlySelectedTabId]);

  // Update menu width and reset expanded items if the menu is collapsed
  useEffect(() => {
    setMenuWidth(menuExpanded ? '322px' : '85px');
    if (!menuExpanded) {
      setExpandedItems({});
    }
  }, [menuExpanded]);

  // Handle menu item selection
  const handleSelectMenuLevel = (passedInMenuItem: IWBSMenuItem) => {
    if (!menuExpanded) {
      toggleMenu();
    }
    interceptMenuClick(passedInMenuItem?.url ?? '', passedInMenuItem?.name ?? '');
  };

  // Toggle item expansion for parent-child menus
  const toggleItemExpansion = (itemId: number) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  return (
    <>
      {menu ? (
        <div style={{ height: `${screenHeight}px`, maxHeight: `${screenHeight}px`, overflowY: 'auto', overflowX: 'hidden', scrollbarWidth:'none', width:'260px'}}>
          <VerticalMenu width={menuWidth} height='97%'>
            <div
              onClick={() => toggleMenu()}
              style={{ cursor: 'pointer', overflow: 'hidden', marginLeft: menuExpanded ? '52%' : '-10%', marginTop: '-20px' }}
            >
              <VerticalMenuItem height='100px' title='' iconType={menuExpanded ? 'arrow_left' : 'arrow'} />
            </div>
            {menu.items.map((menuItem, index1) => {
              const iconType: IconType | undefined = menuItem.icon as IconType;

              if (menuItem.parentId === 0) {
                const childrenMenuItems = menu.items
                  .filter((childMenuItem) => childMenuItem.parentId === menuItem.id)
                  .sort((a, b) => a.sortOrder - b.sortOrder);

                return (
                  <div
                    key={index1}
                    onClick={() => {
                      handleSelectMenuLevel(menuItem);
                      toggleItemExpansion(menuItem.id);
                    }}
                    style={{ cursor: 'pointer', overflow: 'hidden', marginLeft: '-10%', marginRight: '10%' }}
                  >
                    <VerticalMenuItem
                      iconType={iconType}
                      title={menuExpanded ? menuItem.name : ''}
                      defaultOpen={expandedItems[menuItem.id] ?? false}
                      children={
                        childrenMenuItems.length > 0 && menuExpanded
                          ? childrenMenuItems.map((childMenuItem, index2) => {
                              const childMenuItemIconType: IconType | undefined = childMenuItem.icon as IconType;
                              const childrensChildrenMenuItems = menu.items
                                .filter((childrensChildMenuItem) => childrensChildMenuItem.parentId === childMenuItem.id)
                                .sort((a, b) => a.sortOrder - b.sortOrder);

                              return (
                                <div
                                  key={`${index1}-${index2}`}
                                  onClick={() => {
                                    handleSelectMenuLevel(childMenuItem);
                                    toggleItemExpansion(childMenuItem.id);
                                  }}
                                  style={{ cursor: 'pointer', overflow: 'hidden', marginLeft: '-5%', marginRight: '5%' }}
                                >
                                  <VerticalMenuItem
                                    iconType={childMenuItemIconType}
                                    title={menuExpanded ? childMenuItem.name : ''}
                                    defaultOpen={expandedItems[childMenuItem.id] ?? false}
                                    children={
                                      childrensChildrenMenuItems.length > 0
                                        ? childrensChildrenMenuItems.map((childrensChildrenMenuItem, index3) => {
                                            const childrensChildrenMenuItemIconType: IconType | undefined = childrensChildrenMenuItem.icon as IconType;

                                            return (
                                              <div
                                                key={`${index1}-${index2}-${index3}`}
                                                onClick={() => {
                                                  handleSelectMenuLevel(childrensChildrenMenuItem);
                                                  toggleItemExpansion(childrensChildrenMenuItem.id);
                                                }}
                                                style={{ cursor: 'pointer', overflow: 'hidden', marginLeft: '-5%', marginRight: '5%' }}
                                              >
                                                <VerticalMenuItem
                                                  iconType={childrensChildrenMenuItemIconType}
                                                  title={menuExpanded ? childrensChildrenMenuItem.name : ''}
                                                  defaultOpen={expandedItems[childrensChildrenMenuItem.id] ?? false}
                                                />
                                              </div>
                                            );
                                          })
                                        : undefined
                                    }
                                  />
                                </div>
                              );
                            })
                          : undefined
                      }
                    />
                  </div>
                );
              }
            })}
          </VerticalMenu>
        </div>
      ) : null}
    </>
  );
};

export default DefaultMenuBarVertical;
