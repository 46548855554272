import * as React from 'react';
import AppRoutes from './routes/AppRoutes';

import "carbon-react/lib/style/fonts.css"; //CarbonProvider requires these for fonts
import './lib/icons'; // import Font Awesome icon library
import './scss/style.scss'; // import global styles
import { config as AppConfig } from './lib/config';

import CarbonProvider from "carbon-react/lib/components/carbon-provider";
import GlobalStyle from 'carbon-react/lib/style/global-style';
import mintTheme from 'carbon-react/lib/style/themes/mint';
// import sageTheme from "carbon-react/lib/style/themes/sage";



const App = () => {
  React.useEffect(() => {
    document.title = AppConfig.common.documentTitlePrefix;
  }, [])

  return (
    <>
    <CarbonProvider theme={mintTheme}>
      <GlobalStyle />
      <AppRoutes />
    </CarbonProvider>
      
    </>
  )
};

export default App;
