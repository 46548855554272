import React from 'react';

const LaunchDesktopRoutine = React.lazy(() => import('../../views/DesktopRoutines/LaunchDesktopRoutine'));
const OpenedDesktopRoutine = React.lazy(() => import('../../views/DesktopRoutines/OpenedDesktopRoutine'));


const desktopRoutesProtected = [
  // { path: '/launchdesktoproutine/:routineName', exact: false, name: 'Order Enquiry', component: LaunchDesktopRoutine } //works but get : in URL
  { path: '/launchdesktoproutine', exact: true, name: 'Order Enquiry', component: LaunchDesktopRoutine },
  { path: '/openeddesktoproutine', exact: true, name: 'Order Enquiry', component: OpenedDesktopRoutine }
];

export default desktopRoutesProtected;
