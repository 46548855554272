import React from 'react';

// Page routes
const Login = React.lazy(() => import('../views/Application/Authentication/Login'));
const Logout = React.lazy(() => import('../views/Application/Authentication/Logout'));
const Page404 = React.lazy(() => import('../views/Application/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('../views/Application/Pages/Page500/Page500'));
const Unauthorised = React.lazy(() => import('../views/Application/Pages/Unauthorised/Unauthorised'));

// Identity Server Authenication routes
const CallbackPage = React.lazy(() => import('../views/Application/Authentication/Callback'));
const SilentRenew = React.lazy(() => import('../views/Application/Authentication/SilentRenew'));

const routesPublic = [
  { path: '/callback', exact: true, name: 'IDS4 Callback', component: CallbackPage },
  { path: '/silent-renew', exact: true, name: 'Slient Renew', component: SilentRenew },
  { path: '/unauthorised', exact: true, name: 'Unauthorised', component: Unauthorised },
  { path: '/login', exact: true, name: 'Login', component: Login },
  { path: '/logout', exact: true, name: 'Logout', component: Logout },
  { path: '/404', exact: true, name: 'Not Found', component: Page404 },
  { path: '/500', exact: true, name: 'Error', component: Page500 }
];

export default routesPublic;
