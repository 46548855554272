import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Routes, Route, useLocation, useNavigate, RouteObject } from 'react-router-dom';
import routesPublic from './RoutesPublic';
import routesProtected from './RoutesProtected';
import { suspenseLoader } from '../lib/layout/SuspenseLoader';
//Tab Development
import DefaultLayoutTabs from '../views/Application/DefaultLayout/DefaultLayoutTabs';
//const DefaultLayout = React.lazy(() => import('../views/Application/DefaultLayout/DefaultLayout'));

const AllRoutes = () => {
  return (
    <React.Fragment>
        <Suspense fallback={suspenseLoader()}>
            <Routes>
                {routesPublic.map((route, idx) => {
                return route.component ? (
                    <Route
                    key={`pubrou${idx}`}
                    path={route.path}
                    element={<route.component/>}
                    />
                    ) : (null);
                })}

                {/* Declared with DefaultLayout like this as protection handled inside of this component - components rendered via this it will replace the Outlet /> component in DefaultLayout > so it is rendered inside of the correct html tags and surrounded by e.g. navbar etc */}

                {routesProtected.map((route, idx) => {
                    return route.component ? (
                    //Tab Development
                    // <Route path="/" key={`priroutc${idx}`} element={<DefaultLayout />}>
                    <Route path="/" key={`priroutc${idx}`} element={<DefaultLayoutTabs />}>
                        <Route
                            key={`prirou${idx}`}
                            path={route.path}
                            element={<route.component/>}
                        />
                    </Route>
                    ) : (null);
                })}
                 
                <Route path="*" element={<Navigate to='/dashboard' />} />

            </Routes>
        </Suspense>
    </React.Fragment>
  )
}; export default AllRoutes;
