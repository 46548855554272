import axiosService from '../../lib/axios/axiosService';
import { LoginInput } from './Dtos/LoginInput';
import { LicenceAndSessionCheckDto } from './Dtos/LicenceAndSessionCheckDto';

class AuthService {

  public async authenticate(loginInput: LoginInput): Promise<any> {
    let result = await axiosService.post('api/account/login', loginInput, { withCredentials: true });
    return result.data.result;
  }

  public async licenceAndSessionCheck(checkDto: LicenceAndSessionCheckDto): Promise<any> {
    let result = await axiosService.post('api/account/LicenceAndSessionCheck', checkDto, { withCredentials: true });
    return result.data.result;
  }

  public async SessionExpired(): Promise<any> {
    let result = await axiosService.get('api/account/SessionExpired', { withCredentials: true });
    return result.data.result;
  }
}

export default new AuthService();