// Configuration file for Font Awesome 5 icons

import { library, dom } from '@fortawesome/fontawesome-svg-core'

// Import only the Font Awesome 5 icon that we require
// This massively reduces the production chunk size produced by webpack

// IMPORTANT: Maintain alphabetical order for ease of use

import {
  faAlignJustify,
  faBan,
  faCalendarDay,
  faCartPlus,
  faCheck,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronRight,
  faComment,
  faComments,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faFileAlt,
  faFilter,
  faGlobeEurope,
  faInfoCircle,
  faLock,
  faMapMarkerAlt,
  faMinus,
  faMoneyBill,
  faMoneyBillWave,
  faPaperPlane,
  faPhone,
  faPlus,
  faPoundSign,
  faQuestionCircle,
  faSearch,
  faShoppingBasket,
  faStar,
  faTrash,
  faUser,
  faUserCircle,
  faWrench
} from '@fortawesome/free-solid-svg-icons'

import {
  faBootstrap,
  faFontAwesome,
  faMicrosoft,
  faReact
} from '@fortawesome/free-brands-svg-icons'

library.add(
  // Solid icons
  faAlignJustify,
  faBan,
  faCalendarDay,
  faCartPlus,
  faCheck,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronRight,
  faComment,
  faComments,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faFileAlt,
  faFilter,
  faGlobeEurope,
  faInfoCircle,
  faLock,
  faMapMarkerAlt,
  faMinus,
  faMoneyBill,
  faMoneyBillWave,
  faPaperPlane,
  faPhone,
  faPlus,
  faPoundSign,
  faQuestionCircle,
  faSearch,
  faShoppingBasket,
  faStar,
  faTrash,
  faUser,
  faUserCircle,
  faWrench,
  // Brand icons
  faBootstrap,
  faFontAwesome,
  faMicrosoft,
  faReact
)

dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
