import axiosService from '../../lib/axios/axiosService';
import { EntityActionTypes } from '../../lib/enums/EntityActionTypes';
import IWBSMenu from '../../lib/interfaces/Menu/IWBSMenu';
import IWBSMenuItem from '../../lib/interfaces/Menu/IWBSMenuItem';

class MenuService {

  public async getMenuForUser(): Promise<IWBSMenu> {
    let result = await axiosService.get('api/services/app/WBSMenu/GetMenu');
    return result.data.result;
  }

  public async getDefaultMenuId(): Promise<number> {
    let result = await axiosService.get('api/services/app/WBSMenu/GetDefaultMenuId');
    return result.data.result;
  }

  public async addFavouriteByItemId(menuItemId: number): Promise<boolean> {
    let result = await axiosService.post(`/api/services/app/WBSMenuFavourite/AddByMenuItemId?id=${menuItemId}` );
    return result.data.result;
  }

  public async getAllMenus(): Promise<IWBSMenu[]> {
    let result = await axiosService.get('api/services/app/WBSMenu/GetAll');
    return result.data.result;
  }

  public async deleteFavouriteByItemId(menuItemId: number): Promise<boolean> {
    let result = await axiosService.delete('/api/services/app/WBSMenuFavourite/DeleteByMenuItemId', { params: { id: menuItemId} });
    return result.data.result;
  }

  public async getAllItemsByMenuId(menuId: number): Promise<IWBSMenuItem[]> {
    let result = await axiosService.get(`api/services/app/WBSMenuItem/GetAllByMenuId?id=${menuId}`);
    return result.data.result;
  }

  public async createMenu(menu: IWBSMenu): Promise<number> {
    let result = await axiosService.post('api/services/app/WBSMenu/Create', menu);
    return result.data.result;
  }

  public async createMenuItem(menuItem: IWBSMenuItem): Promise<boolean> {
    let result = await axiosService.post('api/services/app/WBSMenuItem/Create', menuItem);
    return result.data;
  }

  public async updateMenu(menu: IWBSMenu): Promise<boolean> {
    let result = await axiosService.put('api/services/app/WBSMenu/Update', menu);
    return result.data;
  }

  public async updateMenuItem(menuItem: IWBSMenuItem): Promise<boolean> {
    let result = await axiosService.put('api/services/app/WBSMenuItem/Update', menuItem);
    return result.data;
  }

  public async createOrUpdateMenu(entityAction: EntityActionTypes, menu: IWBSMenu): Promise<boolean> {
    let result = entityAction === EntityActionTypes.Edit ? await axiosService.put('api/services/app/WBSMenu/Update', menu) : await axiosService.post('api/services/app/WBSMenu/Create', menu);
    return result.data;
  }

  public async createOrUpdateMenuItem(entityAction: EntityActionTypes, menuItem: IWBSMenuItem): Promise<boolean> {
    let result = entityAction === EntityActionTypes.Edit ? await axiosService.put('api/services/app/WBSMenuItem/Update', menuItem) : await axiosService.post('api/services/app/WBSMenuItem/Create', menuItem);
    return result && result.data ? result.data : false;
  }

  public async deleteMenu(menu: IWBSMenu): Promise<boolean> {
    let res: boolean = false;
    await axiosService.delete('api/services/app/WBSMenu/Delete', { params: menu }).then((result) => {
      res = result.data.success;
    }).catch((result) => {
      console.log (JSON.stringify(result))
      res = result.data.success;
    });
    return res;

  }

  public async deleteMenuItem(menuItem: IWBSMenuItem): Promise<boolean> {
    let result = await axiosService.delete('api/services/app/WBSMenuItem/Delete', { params: menuItem });
    return result.data.success;
  }

  public async setMenuItemsOrder(menuItems: IWBSMenuItem[]): Promise<boolean> {
    let result = await axiosService.post('api/services/app/WBSMenuItem/SetMenuItemsOrder', menuItems);
    return result.data.success;
  }

}

export default new MenuService();
