import { reducer } from './reducer';
import IWBSMenu from '../../lib/interfaces/Menu/IWBSMenu';
import { MenuSidebarContextTypes } from '../../lib/enums/MenuSidebarContextTypes';
import IWBSMenuItem from '../../lib/interfaces/Menu/IWBSMenuItem';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MenuState {
  menu?: IWBSMenu;
  currentAppParentMenuId: number;
  menuSidebarOpenContext?: MenuSidebarContextTypes;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SetMenuAction {
  type: "SET_MENU";
  menu: IWBSMenu;
}

interface SetCurrentAppMenuIdAction {
  type: "SET_CURRENT_APP_MENU_ID";
  id: number;
}

interface SetMenuSidebarContextAction {
  type: "SET_MENU_SIDEBAR_CONTEXT";
  context: MenuSidebarContextTypes | undefined;
}

interface SetMenuFavourites {
  type: "SET_MENU_FAVOURITES";
  menuFavourites: IWBSMenuItem[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownMenuActions = SetMenuAction | SetCurrentAppMenuIdAction | SetMenuSidebarContextAction | SetMenuFavourites;

export const unloadedMenuState: MenuState = {
    menu: undefined,
    currentAppParentMenuId: 0,
    menuSidebarOpenContext: undefined
};

export { reducer };
