import { AES, enc } from 'crypto-js';
import { config } from '../config';

const localStorageCipherKey: string = process.env.NODE_ENV === 'development' ? config.dev.localStorageCipherKey : config.build.localStorageCipherKey;
export const getDecryptedValueFromLocalStorage = (key: string) => {
    const encryptedValueFromLocalStorage = localStorage.getItem(key);
    if(encryptedValueFromLocalStorage){
        let bytes = AES.decrypt(encryptedValueFromLocalStorage, localStorageCipherKey);
        return  bytes.toString(enc.Utf8);
    }

    return null
  }

  export const encryptAndStoreValueToLocalStorage = (key: string, plainTextStringToStore: string) => {
    try{
        const encryptedStringToStoreAsByteArray = AES.encrypt(plainTextStringToStore, localStorageCipherKey);
        localStorage.setItem(key, encryptedStringToStoreAsByteArray.toString());
        return true;
    }
    catch{
        return false
    }
  }