import axios from 'axios';
import { store } from '../..';
import { config as AppConfig } from '../config'
import moment from 'moment';
import qs from 'qs';

//allows cookies to be set from server, on client app
axios.defaults.withCredentials = true;
const axiosService = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? AppConfig.dev.baseUrl + AppConfig.dev.appName : window.location.origin,
  timeout: AppConfig.common.httpApiServiceTimeOut
});

/*
 * Add the Authentication token to the request header based on the Authentication method required
 *
 */
axiosService.interceptors.request.use((config) => {
  // Get Identity Server access token and add to request header
  const authToken = store.getState().oidc.user ? store.getState().oidc.user?.access_token : ''
  config.headers.Authorization = `Bearer ${authToken}`;

  // Correct an issue with axios serializing date always to UTC time
  config.paramsSerializer = (params) => qs.stringify(params, { serializeDate: (date: Date) => moment(date).format('YYYY-MM-DDTHH:mm:ssZ') });

  return config;
})

export default axiosService;
