import React from 'react';


const PurchaseOrderEntry = React.lazy(() => import('../../views/PurchaseOrders/PurchaseOrderEntry/PurchaseOrderEntry'));
const PurchaseOrderEnquiry = React.lazy(() => import('../../views/PurchaseOrders/PurchaseOrderEnquiry/PurchaseOrderEnquiry'));
const purchaseOrdersRoutesProtected = [
  { path: '/purchaseorders/purchase-order-entry', exact: true, name: 'Purchase Order Entry', component: PurchaseOrderEntry },
  { path: '/purchaseorders/purchase-order-enquiry', exact: true, name: 'Purchase Order Enquiry', component: PurchaseOrderEnquiry },
];

export default purchaseOrdersRoutesProtected;
