import { Reducer, Action } from "redux";
import { MenuState, KnownMenuActions, unloadedMenuState } from ".";

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<MenuState> = (state: MenuState | undefined, incomingAction: Action): MenuState => {
  if (state === undefined) {
    return unloadedMenuState;
  }

  const action = incomingAction as KnownMenuActions;
  switch (action.type) {
    case "SET_MENU":
      return {
        ...state,
        menu: action.menu
      }
    case "SET_CURRENT_APP_MENU_ID":
      return {
        ...state,
        currentAppParentMenuId: action.id
      }
    case "SET_MENU_SIDEBAR_CONTEXT":
      return {
        ...state,
        menuSidebarOpenContext: action.context
      }
    case "SET_MENU_FAVOURITES":
      return {
        ...state,
        menu: state.menu ? {
          ...state.menu,
          favourites: action.menuFavourites
        } : undefined
      }
  }

  return state;
};
