import React, { Component, ErrorInfo, ReactNode } from "react";
import { Navigate } from "react-router";

interface Props {
  children: ReactNode;
}
  
interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };
  
    public static getDerivedStateFromError(_: Error): State {
       // Update state so the next render will show the fallback UI.
       return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      console.log(`Uncaught error occurred, error: ${error} errorInfo: ${errorInfo}`)
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);// TODO - log to ErrorService to post front-end client error to back-end for Stackify logging
    }
    public render() {
      if (this.state.hasError) {
        return <Navigate to={'/500'} />
      }

      return this.props.children; 
    }
  }

  export default ErrorBoundary;