import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import configService from "../../services/Configuration/configService";

const useConfigurationManager = () => {
    const dispatch = useDispatch();
    //const app = useSelector((state: ApplicationState) => state);

    const fetchUiTheme = async () => {
        try {
          await configService.getUiTheme().then((theme) => {
            dispatch({ type: "SET_UI_THEME", theme});
          });
        } catch (err) {          
          console.log(err);
          if(err instanceof Error) {
            console.log(err.message);
          }
          else {
            console.log("Something threw that wasn't an error message ")
          }
        }
    };

    useEffect(() => {
      fetchUiTheme();
    }, []);
};

export default useConfigurationManager;
