import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";

const useMenuSelectInterceptorTabs = () => {

    //redux state
    const user = useSelector((state: ApplicationState) => state.oidc.user); //Get user from store
    const tabsState = useSelector((state: ApplicationState) => state.app.tabs); 

    //react hooks
    const reduxDispatch = useDispatch();
    
    //Handles clicking a menu object and managing the resultant component opening in a new / existing tab - old, no longer used
    // const interceptMenuClick = (urlToNavTo: string, componentTitle: string) => {
    //     if(urlToNavTo !== '' && urlToNavTo !== '#'){
    //       const potentialExistingTab = tabsState.find((tab) => tab?.route === urlToNavTo);
    //       if(potentialExistingTab)
    //         reduxDispatch({type: 'SET_CURRENT_TAB_ID', currentlySelectedId: potentialExistingTab.id, currentlySelectedIndex: potentialExistingTab.tabIndex, username: user?.profile?.preferred_username});
    //       else
    //         reduxDispatch({type: 'ADD_NEW_TAB', tabToAdd: {title: componentTitle, route: urlToNavTo, id: urlToNavTo, tabIndex: tabsState.length }, username: user?.profile?.preferred_username });
    //     }
    //   }

    const interceptMenuClick = (
        urlToNavTo: string,
        componentTitle: string
    ) => {
        if (urlToNavTo !== '' && urlToNavTo !== '#') {
            const potentialExistingTab = tabsState.find((tab) => tab?.route === urlToNavTo);
            if (potentialExistingTab) {
                reduxDispatch({
                    type: 'SET_CURRENT_TAB_ID',
                    currentlySelectedId: potentialExistingTab.id,
                    currentlySelectedIndex: potentialExistingTab.tabIndex,
                    username: user?.profile?.preferred_username,
                });
            } else {
                reduxDispatch({
                    type: 'ADD_NEW_TAB',
                    tabToAdd: {
                        title: componentTitle,
                        route: urlToNavTo,
                        id: urlToNavTo,
                        tabIndex: tabsState.length,
                    },
                    username: user?.profile?.preferred_username,
                });
            }
        }
    };

      return {interceptMenuClick}

}; export default useMenuSelectInterceptorTabs;