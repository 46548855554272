import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, Outlet} from 'react-router-dom';
import { ApplicationState } from '../../../store';
import { suspenseLoader } from '../../../lib/layout/SuspenseLoader';
import { Box, Icon, Tab, Tabs } from '../../../lib/carbon-ui';
import authService from '../../../services/Authentication/authService';
import userManager from '../../../lib/identityserver4/userManager';
import { useDispatch } from 'react-redux';
import IWBSMenu from '../../../lib/interfaces/Menu/IWBSMenu';
import menuService from '../../../services/Menu/menuService';
import { AxiosError } from 'axios';
import useConfigurationManager from '../../../lib/hooks/useConfigurationManager';
import { getDecryptedValueFromLocalStorage } from '../../../lib/methods/localStorageMethods';
import { AppTab } from '../../../lib/interfaces/AppTab/IAppTab';
import DefaultMenuBarVertical from './DefaultMenuBarVertical';

const DefaultMenuBar = React.lazy(() => import('./DefaultMenuBar'));
const DefaultNavBar = React.lazy(() => import('./DefaultNavBar'));

const DefaultLayoutTabs = ()  => {

    useConfigurationManager();
    //hooks
    const navigate = useNavigate(); //leave in as temp fix - eventListeners are not being added to the DOM without this being declared (on refresh)
    const reduxDispatch = useDispatch();

    //redux state
    const user = useSelector((state: ApplicationState) => state.oidc.user); //Get user from store
    const tabsState = useSelector((state: ApplicationState) => state.app.tabs); 
    const selectedTabId = useSelector((state: ApplicationState) => state.app.currentTabId); 

    const [authCheckFinished, setAuthCheckFinished] = useState(false); // controls redirect or go to route after auth - need to test whether user is authenticated / authoirised / session has expired first, once all have been tested (server side calls) then we can either redirect or let them in
    const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(); // User authentication valid
    const [ isSessionExpired, setIsSessionExpired ] = useState<boolean>(); // User authentication valid
    const [ isAuthorised, setIsAuthorised ] = useState<boolean>(); // User authorised to proceed to route
    const [menu, setMenu] = useState<IWBSMenu>();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [menuExpanded, setMenuExpanded] = useState(false);

    const toggleMenu = () => {
        setMenuExpanded((prev) => !prev);
    };
    //vars that aren't state
    var routeUrl = window.location.pathname;
    if (routeUrl === '/') routeUrl = '/dashboard';
    const clickHandlers: { element: HTMLElement; handler: (event: MouseEvent) => void }[] = [];   

    //Every time the route changes check the user is auth'd for it - set state - this will redirect to logout > login if they try access something they shouldnt be able to 
    useEffect(() => { 
        checkAndValidateCurrentRouteForUser();
    }, [routeUrl])

    //used to handle the height of teh newly added vertical menubar, to match with teh current screen height
    useEffect(() => {
        const handleResize = () => {
          setWindowHeight(window.innerHeight);
        };
    
        // Attach the event listener when the component mounts
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    //means first time page has been visited from login, add a new tab to the tab state (for the dashboard) OR tabs from localstorage if the user has logged onto this machine before
    useEffect(() => {
        if(user?.profile?.preferred_username && (!tabsState || tabsState.length < 1)){
    
          const tabStateFromLocalStorageStringified = getDecryptedValueFromLocalStorage(`open-tabs-${user?.profile?.preferred_username}`);
          if(tabStateFromLocalStorageStringified){
            const tabStateFromLocalStorage : AppTab[] = JSON.parse(tabStateFromLocalStorageStringified);
            if(tabStateFromLocalStorage && tabStateFromLocalStorage.length > 0){
              reduxDispatch({type: 'INITIALISE_TABS_FROM_LS', username: user?.profile?.preferred_username});
              return;
            }
          }
          reduxDispatch({type: 'ADD_NEW_TAB', tabToAdd: {title: 'Dashboard', route: '/dashboard', id: '/dashboard', tabIndex: 0 }, username: user?.profile?.preferred_username });
        }       
      }, [tabsState, user])



  //works on login / tab change - but then not after page refresh

    const handleCloseTab = (event: React.MouseEvent<HTMLDivElement>) => {
        //const idOfTabToClose = event.currentTarget.id.replace('closeable', '')
        reduxDispatch({type: 'CLOSE_TAB', idOfTabToClose: event.currentTarget.id.replace('closeable', ''), username: user?.profile?.preferred_username, currentlySelectedTabId: selectedTabId  })
    }
    

    //Sage-Carbon doesn't have closeable tabs - use vanilla Javascript to add event listeners to the icosn that are on each
    useEffect(() => {
        tabsState.forEach((tab) => {
            const currentTabFromDOM = document.getElementById(`closeable${tab.id}`);
            //console.log('EventListener attach attempt:, currentTabFromDOM', currentTabFromDOM)
            if (currentTabFromDOM) {               
                const clickHandler = (event: any) => {handleCloseTab(event)};
                currentTabFromDOM.addEventListener("click", clickHandler);
                clickHandlers.push({ element: currentTabFromDOM, handler: clickHandler});
            }
        });
    
        //handle remove on each rerender so multiple event listeners are not added to the dom / causeing the event to be run multiple times
        return () => {
        clickHandlers.forEach(({element, handler }) => {
            element.removeEventListener("click", handler);
        });
        };

   }, [tabsState , handleCloseTab ]);

    //dispatch to redux to set the currentlySelectedTabId = the tabs component uses this value in central state to define which tab it is currently viewing
    const handleTabChange = (tabId: string) => {
        const foundTabFromCentralStateList = tabsState.find((tab) => tab?.id === tabId);
        if(foundTabFromCentralStateList){
            reduxDispatch({type: 'SET_CURRENT_TAB_ID', currentlySelectedId: tabId, currentlySelectedIndex: foundTabFromCentralStateList.tabIndex, username: user?.profile?.preferred_username});
        }
        
    }

    const checkAndValidateCurrentRouteForUser = async () => {
        // Check that the licence and session are still valid for the user
        await authService.licenceAndSessionCheck({ sessionToken: '', routerCheck: true, overrideExisting: false })
            .then((resp) => {
                if (resp.errorCode === 810) {
                    // A 810 error is returned when the session on this browser is valid, i.e. the user has logged in on another browser
                    authService.SessionExpired().then((resp1) => {
                        userManager.removeUser();
                        setIsSessionExpired(true);
                        setIsAuthenticated(false);
                        setIsAuthorised(false);
                        //setIsChecked(true);
                        setAuthCheckFinished(true)
                    });
                } 
                else {
                    if (resp.errorCode === 0) {
                        setIsSessionExpired(false)
                        setIsAuthenticated(true);
                        // Now check that the user has authorised access to the route by checking it exists in their menu
                        if (!menu) {// If the menu is undefined in Redux state, get the menu for the user first                       
                            menuService.getMenuForUser().then((fetchedMenu) => {
                                reduxDispatch({ type: "SET_MENU", menu: fetchedMenu});
                                // console.log('Has url in menu item:' + fetchedMenu.items.some(f => f.url === routeUrl));
                                if (fetchedMenu.items.some(f => f.url === routeUrl)) {
                                    let menuItem = fetchedMenu.items.find(m => m.url === routeUrl);
                                    if (menuItem?.parentId !== 0) menuItem = fetchedMenu.items.find(m => m.id === menuItem?.parentId);
                                    reduxDispatch({ type: "SET_CURRENT_APP_MENU_ID", id: menuItem?.id });
                                    setIsAuthorised(true);
                                }
                                //setIsChecked(true); // IMPORTANT: setIsChecked must be last
                                setAuthCheckFinished(true)
                            });
                        } 
                        else {
                            if (menu.items.some(f => f.url === routeUrl)) {
                                let menuItem = menu.items.find(m => m.url === routeUrl);
                                if (menuItem?.parentId !== 0) menuItem = menu.items.find(m => m.id === menuItem?.parentId);
                                reduxDispatch({ type: "SET_CURRENT_APP_MENU_ID", id: menuItem?.id });
                                setIsSessionExpired(false)
                                setIsAuthenticated(true);
                                setIsAuthorised(true);
                                setAuthCheckFinished(true);
                            }
                           
                            //setIsChecked(true); // IMPORTANT: setIsChecked must be last
                        }
                    } 
                    else {
                        setIsSessionExpired(false);
                        setIsAuthenticated(false);
                        setIsAuthorised(false);
                        setAuthCheckFinished(true);
                        //setIsChecked(true); // IMPORTANT: setIsChecked must be last
                    }
                }
            })
            .catch((error: AxiosError) => {
                console.log(error);
                setIsSessionExpired(false);
                setIsAuthenticated(false);
                setIsAuthorised(false);
                setAuthCheckFinished(true);
            });
    };

    const userIsAuthdToAccessProtectedRoute = () => { return user && isAuthenticated && isAuthorised && !isSessionExpired }

    return (
        <React.Fragment>
            {
                authCheckFinished ? 
                    userIsAuthdToAccessProtectedRoute() ?
                        <React.Fragment>
                            <Box display="flex" flexDirection="column" min-height="100vh" width="100%">
                                <Box
                                    height="41px"
                                    width="100%"
                                    display="flex"
                                    flexDirection="row"
                                    className="navbar-nav"
                                    position="fixed" // Sticky positioning
                                    top={0} // Stick to the top of the viewport
                                    backgroundColor="white" // Optional: Maintain a background to prevent overlap issues
                                >
                                    <Box display="flex" flexDirection="column" width="100%" position="sticky">
                                        <Box height="41px" width="100%" display="flex" flexDirection="row" position="sticky">
                                            <Suspense fallback={suspenseLoader()}>
                                            <DefaultNavBar />
                                            </Suspense>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box display="flex" flexDirection="row" flexGrow={1} overflowX="hidden" overflowY="hidden" mt="0px" minHeight={windowHeight * 0.955}>
                                <Box
                                        p={-1}
                                        minHeight={1}
                                        flexDirection="column"
                                        overflowY="hidden"
                                        overflowX="hidden"
                                        position="fixed"
                                        top={5}
                                        left={0}
                                        height="100vh"
                                        width={menuExpanded ? "270px" : "80px"}
                                    >
                                    <DefaultMenuBarVertical menuExpanded={menuExpanded} toggleMenu={toggleMenu}/>
                                </Box>
                               

                                <Box
                                        p={3}
                                        width="100%"
                                        className="app-body"
                                        mt={4}
                                        ml={menuExpanded ? "270px" : "80px"} // Adjust margin dynamically
                                    >
                                    <main className="main">
                                        <Suspense fallback={suspenseLoader()}>
                                            <Tabs size='large' align='left' position='top' selectedTabId={selectedTabId ? selectedTabId : '/dashboard'} setLocation={true}onTabChange={handleTabChange}>
                                                {
                                                    tabsState.map((tab, id) => {
                                                        const shouldRenderOutlet = selectedTabId && selectedTabId !== '' && selectedTabId === tab.id;

                                                        return (
                                                            <Tab 
                                                                // errorMessage='error' 
                                                                // warningMessage='warning' 
                                                                // infoMessage='info' 
                                                                tabId={tab.id} title={tab.title} key={tab.id}
                                                                siblings={[
                                                                    <div id={`closeable${tab.id}`}> 
                                                                        <Icon type='cross' fontSize='small' mt='-20px' mr='-15px' />
                                                                    </div>
                                                                ]} 
                                                                titlePosition='before'
                                                            >
                                                                {shouldRenderOutlet ? <Box pt={1}><Outlet/></Box> : null}
                                                                
                                                            </Tab>
                                                        )
                                                    })
                                                }
                                                
                                            </Tabs>
                                        </Suspense>
                                    </main>                              
                                </Box>
                            </Box>
                        </React.Fragment>

                    : 
                        <Navigate to='/logout'/>
                : null
            }
        </React.Fragment>
    )

}; export default DefaultLayoutTabs


//TODO -
//Intermittent fault - when you refresh, the event listners do not get added back on all the time - push live and test
