import React from 'react';


const SalesOrderEntry = React.lazy(() => import('../../views/SalesOrders/SalesOrderEntry/SalesOrderEntry'));
const SalesOrderEnquiry = React.lazy(() => import('../../views/SalesOrders/SalesOrderEnquiry/SalesOrderEnquiry'));
const CuttingTable = React.lazy(() => import('../../views/SalesOrders/CuttingTable/CuttingTable'))
const RouteMaintenance = React.lazy(() => import('../../views/Maintenance/RouteMaintenance/RouteMaintenance'))
const VehicleMaintenance = React.lazy(() => import('../../views/Maintenance/VehicleMaintenance/VehicleMaintenance'))
const StaffMaintenance = React.lazy(() => import('../../views/Maintenance/StaffMaintenance/StaffMaintenance'))
const CreditControl = React.lazy(() => import('../../views/SalesOrders/CreditControl/CreditControl'))
const RetailOrderEntry = React.lazy(() => import('../../views/SalesOrders/SalesOrderEntry/RetailOrderEntry'))
const BackOrderQueue = React.lazy(() => import('../../views/SalesOrders/BackOrderQueue/BackOrderQueue'))
const CuttingQueue = React.lazy(() => import('../../views/SalesOrders/CuttingQueue/CuttingQueue'))
const PrintDeliveryNotes = React.lazy(() => import('../../views/SalesOrders/PrintDeliveryNotes/PrintDeliveryNotes'))
const PrintSalesInvoices = React.lazy(() => import('../../views/SalesOrders/PrintSalesInvoices/PrintSalesInvoices'))
const salesOrdersRoutesProtected = [
  { path: '/salesorders/sales-order-entry', exact: true, name: 'Sales Order Entry', component: SalesOrderEntry },
  { path: '/salesorders/sales-order-enquiry', exact: true, name: 'Sales Order Enquiry', component: SalesOrderEnquiry },
  { path: '/salesorders/route-maintenance', exact: true, name: 'Route Maintenance', component: RouteMaintenance },
  { path: '/salesorders/vehicle-maintenance', exact: true, name: 'Vehicle Maintenance', component: VehicleMaintenance },
  { path: '/salesorders/staff-maintenance', exact: true, name: 'Staff Maintenance', component: StaffMaintenance },
  { path: '/salesorders/credit-control', exact: true, name: 'Credit Control', component: CreditControl },
  { path: '/salesorders/entry', exact: true, name: 'Retail Order Entry', component: RetailOrderEntry },
  { path: '/salesorders/back-orders', exact: true, name: 'Back Order Queue', component: BackOrderQueue },
  { path: '/salesorders/cutting-queue', exact: true, name: 'Cutting Queue', component: CuttingQueue },
  { path: '/salesorders/cutting-table', exact: true, name: 'Cutting Table', component: CuttingTable },
  { path: '/salesorders/delivery-notes', exact: true, name: 'Print Delivery Notes', component: PrintDeliveryNotes },
  { path: '/salesorders/sales-invoices', exact: true, name: 'Print Sales Invoices', component: PrintSalesInvoices },
];

export default salesOrdersRoutesProtected;
