import axiosService from '../../lib/axios/axiosService';

class ConfigService {

  public async getUiTheme(): Promise<string> {
    let result = await axiosService.get('api/services/app/Configuration/GetUiTheme');
    return result.data.result;
  }

  public async setUiTheme(theme: string): Promise<boolean> {
    let result = await axiosService.post('/api/services/app/Configuration/ChangeUiTheme', { theme: theme });
    return result.data.success;
  }

}

export default new ConfigService();
