import { Reducer, Action } from "redux";
import { AppState, KnownAppActions, unloadedAppState } from ".";
import { encryptAndStoreValueToLocalStorage, getDecryptedValueFromLocalStorage } from "../../lib/methods/localStorageMethods";
import { AppTab } from "../../lib/interfaces/AppTab/IAppTab";

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<AppState> = (state: AppState | undefined, incomingAction: Action): AppState => {
  if (state === undefined) {
    return unloadedAppState;
  }

  const action = incomingAction as KnownAppActions;
  switch (action.type) {
    case "SET_APP_SIDEBAR_CONTEXT":
      return {
        ...state,
        appSidebarOpenContext: action.context,
      }

    case "SET_COLUMN_SELECT_SIDEBAR_OPEN":
        return {
          ...state,
          columnSelectSidebarOpenContext: action.columnSelectSidebarOpenContextPassedIn
        } 
      case "SET_COLUMN_SELECT_SIDEBAR_CONTEXT":
        return {
          ...state,
          selectableTableColumns: action.columnSelectSidebarCheckboxesPassedIn,
        }
    case "SET_UI_THEME":
      return {
        ...state,
        uiTheme: action.theme,
      }

    //add the tab to the tabs state AND set the currently selected tab id to its ID
    case "ADD_NEW_TAB":

    const tabsAfterNewOneAdded = [...state.tabs, action.tabToAdd];

    encryptAndStoreValueToLocalStorage(`open-tabs-${action.username}`, JSON.stringify(tabsAfterNewOneAdded));
    encryptAndStoreValueToLocalStorage(`current-tab-${action.username}`, action?.tabToAdd?.id);

      return {
        ...state,
        tabs: [...state.tabs, action.tabToAdd],
        currentTabId: action.tabToAdd.id,
        currentTabIndex: action.tabToAdd.tabIndex
      }

    case "CLEAR_TAB_STATE" : 

    return {
      ...state,
      tabs: []
    }


    //added for retail order entry - to handle the existing tab being replaced with the query params
    case "ADD_OR_REPLACE_EXISTING_TAB":

    let tabsAfterOneSwappedOutOrOneAdded : AppTab[];
    const tabState = [...state.tabs];

    const potentialExistingTab = tabState.find((tab, index) => tab.route === action.tabToReplace.route)
    if(!potentialExistingTab){
      tabsAfterOneSwappedOutOrOneAdded = [...state.tabs, action.tabToReplace];
    }
    else{
      tabsAfterOneSwappedOutOrOneAdded = state.tabs.map((tab, index) => {
        return tab.route === action.tabToReplace.route ? action.tabToReplace : tab
      })
    }

    encryptAndStoreValueToLocalStorage(`open-tabs-${action.username}`, JSON.stringify(tabsAfterOneSwappedOutOrOneAdded));
    encryptAndStoreValueToLocalStorage(`current-tab-${action.username}`, action?.tabToReplace.id);

      return {
        ...state,
        tabs: [...tabsAfterOneSwappedOutOrOneAdded],
        currentTabId: action.tabToReplace.id,
        currentTabIndex: action.tabToReplace.tabIndex
      }

    case "CLEAR_TAB_STATE" : 

    return {
      ...state,
      tabs: []
    }


    case "INITIALISE_TABS_FROM_LS":

    //only call when you know localStorage has tabstate inb it
      const tabStateFromLocalStorageStringified = getDecryptedValueFromLocalStorage(`open-tabs-${action.username}`);

      const currentlySelectedTabId = getDecryptedValueFromLocalStorage(`current-tab-${action.username}`); 

      if(tabStateFromLocalStorageStringified){
        const tabStateFromLocalStorage : AppTab[] = JSON.parse(tabStateFromLocalStorageStringified);
        const currentlySelectedTab : AppTab | undefined = tabStateFromLocalStorage.find((tab, index) => tab.id === currentlySelectedTabId)

        return {
          ...state,
          tabs: [...tabStateFromLocalStorage],
          currentTabId: currentlySelectedTab ? currentlySelectedTab.id : '/dashboard',
          currentTabIndex: currentlySelectedTab ? currentlySelectedTab.tabIndex : 0
        }
      }
      else{
        return state;
      }
      
    case "SET_CURRENT_TAB_ID":

      encryptAndStoreValueToLocalStorage(`current-tab-${action.username}`, action?.currentlySelectedId);
      
      return {
        ...state,
        currentTabId: action.currentlySelectedId,
        currentTabIndex: action.currentlySelectedIndex
      }

    case "CLOSE_TAB":

      const shallowCopyOfTabsState = [...state.tabs];

      const tabBeingClosed = shallowCopyOfTabsState.find((tab, index) => tab.id === action.idOfTabToClose)

      const tabsStateAfterClosure = shallowCopyOfTabsState.filter((tab, index) => {
        return tab.id !== action.idOfTabToClose
      })

      //rejig the index of each one - although the program doesn't use this (was added when testing react-tabs), it is useful for ordering
      for(var i = 0; i < tabsStateAfterClosure.length; i++){
        tabsStateAfterClosure[i].tabIndex = i;
      }


      //work out which tab to select after one is closed
      let nextTabToSelectAfterClose : AppTab | undefined;

      //means they are closing the tab they are currently on - select the next one in the list
      if(action.currentlySelectedTabId === action.idOfTabToClose){
        //tabIndex used to work out which tab to select next - if last tab in list is being closed, rejig to get the correct tabIndex to use to select the next selectable tab 
        const tabIndexToSelect = tabBeingClosed?.tabIndex === state.tabs.length - 1 ? tabBeingClosed?.tabIndex - 1 : tabBeingClosed?.tabIndex;
        nextTabToSelectAfterClose = tabsStateAfterClosure.find((tab, index) => tab.tabIndex === tabIndexToSelect)
      }
      //means they are selecting some other tab other than the one they are on, currently selected tab will be 
      else{
        nextTabToSelectAfterClose = tabsStateAfterClosure.find((tab, index) => tab.id === action.currentlySelectedTabId)
      }

      //store new tab state to local storage
      encryptAndStoreValueToLocalStorage(`open-tabs-${action.username}`, JSON.stringify(tabsStateAfterClosure));

      //store currently selected to local storage
      encryptAndStoreValueToLocalStorage(`current-tab-${action.username}`, nextTabToSelectAfterClose ? nextTabToSelectAfterClose.id : '/dashboard');



      return {
        ...state,
        tabs: [...tabsStateAfterClosure],
        currentTabId : nextTabToSelectAfterClose ? nextTabToSelectAfterClose.id : '/dashboard',
        currentTabIndex: nextTabToSelectAfterClose ? nextTabToSelectAfterClose.tabIndex : 0
      }
  }

  return state;
};
